body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  border: 10px solid #fff;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Oswald, sans-serif;
  color: #fff;
  font-size: 45px;
  line-height: 44px;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  text-transform: none;
}

h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 32px;
  line-height: 36px;
  font-weight: 700;
}

h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Lato, sans-serif;
  font-size: 18px;
  line-height: 30px;
  font-weight: 900;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Lato, sans-serif;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
}

h5 {
  margin-top: 10px;
  margin-bottom: 5px;
  font-family: Lato, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
}

h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}

p {
  margin-bottom: 5px;
  font-family: Lato, sans-serif;
  color: #737373;
  line-height: 24px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0px;
}

.a_webflow {
  display: block;
  padding: 20px 30px;
  border-right: 1px none #fff;
  font-family: Oswald, sans-serif;
  color: #000;
  text-decoration: none;
  text-transform: uppercase;
}

.button {
  display: inline-block;
  margin-top: 20px;
  padding: 15px 20px;
  border-right-style: none;
  background-color: #000;
  -webkit-transition: background-color 500ms ease, color 500ms ease;
  transition: background-color 500ms ease, color 500ms ease;
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
}

.button:hover {
  background-color: #fff;
  color: #000;
}

.button.hollow {
  margin-top: 30px;
  border: 1px solid #000;
  background-color: transparent;
  -webkit-transition: background-color 500ms ease, border-color 500ms ease, color 500ms ease;
  transition: background-color 500ms ease, border-color 500ms ease, color 500ms ease;
  color: #000;
}

.button.hollow:hover {
  border-color: #2a8241;
  background-color: #2a8241;
  color: #fff;
}

.button.hollow.single {
  margin-top: 0px;
}

.main-slider {
  height: 100%;
  padding-right: 10px;
  padding-left: 10px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.49)), to(transparent)), url('src/assets/images/webflow/Dollarphotoclub_56332376-min.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.49), transparent), url('src/assets/images/webflow/Dollarphotoclub_56332376-min.jpg');
  background-position: 0px 0px, 50% 0px;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
}

.main-slider.alternative {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(18, 54, 38, 0.64)), to(rgba(18, 54, 38, 0.64))), url('src/assets/images/webflow/3361b5e1.jpg');
  background-image: linear-gradient(180deg, rgba(18, 54, 38, 0.64), rgba(18, 54, 38, 0.64)), url('src/assets/images/webflow/3361b5e1.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
}

.hero-heading {
  font-size: 60px;
  font-weight: 400;
  letter-spacing: 4px;
  text-transform: uppercase;
}

.hero-heading.second {
  margin-top: 50px;
}

.hero-subheader {
  margin-top: 30px;
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.features-section {
  margin-top: 10px;
}

.nav-link {
  padding: 25px;
  border-left: 1px solid hsla(0, 0%, 100%, 0.2);
  border-right-style: none;
  background-color: transparent;
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
  color: #fff;
  font-weight: 300;
  letter-spacing: 1px;
}

.nav-link:hover {
  background-color: hsla(0, 0%, 100%, 0.09);
  color: #fff;
}

.nav-link.w--current {
  padding-right: 25px;
  padding-left: 25px;
  background-color: hsla(0, 0%, 100%, 0.09);
  color: #fff;
}

.nav-link.black {
  display: inline-block;
  border-right: 0px none rgba(0, 0, 0, 0.2);
  border-top-color: rgba(0, 0, 0, 0.15);
  border-left-width: 1px;
  border-left-color: rgba(0, 0, 0, 0.15);
  color: #000;
}

.nav-link.black:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

.nav-link.black.w--current {
  border-right-style: none;
  border-right-width: 0px;
  border-left-style: solid;
}

.nav-link.contact {
  padding-right: 30px;
}

.title-features {
  margin-top: 15px;
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.features-div {
  height: 100%;
  margin-right: 10px;
  margin-left: 0px;
  padding: 40px 20px 60px;
  margin-bottom:0;
  background-color: #a3d6b2;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(163, 210, 214, 0.76)), color-stop(89%, #6fadaa)), url('src/assets/images/canon/night.JPG');
  background-image: linear-gradient(180deg, rgba(163, 210, 214, 0.66), #6fadaa 89%), url('src/assets/images/canon/night.JPG');
  background-position: top;
  background-size: auto, cover;
}

.features-div.second {
  margin-right: 10px;
  background-color: #83cc97;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(156, 173, 114, 0.69)), to(rgba(112, 156, 79, 0.96))), url('src/assets/images/canon/closeup.JPG');
  background-image: linear-gradient(180deg, rgba(156, 173, 114, 0.69), rgba(112, 156, 79, 0.96)), url('src/assets/images/canon/closeup.JPG');
  background-repeat: no-repeat;
  background-position: top;
  background-size: auto, cover;
}

.features-div.third {
  margin-right: 0px;
  background-color: #57c275;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(114, 173, 132, 0.64)), to(#508c62)), url('src/assets/images/canon/haus.JPG');
  background-image: linear-gradient(180deg, rgba(114, 173, 132, 0.64), #508c62), url('src/assets/images/canon/haus.JPG');
  background-position: top;
  background-size: auto, cover;
}

.column {
  padding-right: 0px;
  padding-left: 0px;
}

.navbar {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  display: block;
  width: 100%;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.2);
  background-color: transparent;
}

.section {
  padding-top: 120px;
  padding-bottom: 120px;
}

.section.about {
  padding-top: 120px;
  padding-bottom: 120px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.14)), to(transparent)), url('src/assets/images/webflow/6318df839933298282ecfb87_2222.png');
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.14), transparent), url('src/assets/images/webflow/6318df839933298282ecfb87_2222.png');
  background-position: 0px 0px, 50% 100%;
  background-size: auto, auto 700px;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, scroll;
}

.section.about.alternative {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.14)), to(transparent)), url('src/assets/images/webflow/iphone.jpg');
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.14), transparent), url('src/assets/images/webflow/iphone.jpg');
  background-position: 0px 0px, 80% 67px;
  background-size: auto, 322px 700px;
}

.section.process {
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(64%, #fff), to(hsla(0, 0%, 100%, 0.35))), url('src/assets/images/webflow/1222.jpg');
  background-image: linear-gradient(180deg, #fff 64%, hsla(0, 0%, 100%, 0.35)), url('src/assets/images/webflow/1222.jpg');
  background-position: 0% 0%, 50% 100%;
  background-size: auto, contain;
  background-repeat: repeat, no-repeat;
}

.section-title {
  margin-top: 0px;
  margin-bottom: 25px;
  font-family: Lato, sans-serif;
  font-size: 27px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.testimonials-section {
  margin-top: 0px;
  padding-top: 80px;
  padding-bottom: 40px;
  border-right-style: none;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('src/assets/images/webflow/3361b5e1.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('src/assets/images/webflow/3361b5e1.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
}

.testimonials-section.alternative {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url('src/assets/images/webflow/Dollarphotoclub_56332376-min.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('src/assets/images/webflow/Dollarphotoclub_56332376-min.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
}

.emphasized-text {
  margin-bottom: 20px;
  font-family: Lato, sans-serif;
  color: #6cbd0f;
  font-size: 18px;
  line-height: 25px;
  font-style: italic;
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
}

.emphasized-text.right {
  font-weight: 100;
  text-align: right;
}

.features-text {
  color: #fff;
  line-height: 24px;
  font-weight: 400;
  text-align: left;
}

.navigation-div {
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 7px;
}

.navigation-div.black {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
  padding-top: 0px;
  border: 1px none rgba(0, 0, 0, 0.09);
}

.brand {
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 1px solid hsla(0, 0%, 100%, 0.2);
}

.brand.w--current {
  padding-top: 10px;
  padding-bottom: 10px;
  border-right: 1px solid hsla(0, 0%, 100%, 0.22);
  border-left: 1px none hsla(0, 0%, 100%, 0.22);
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
}

.brand.w--current:hover {
  background-color: hsla(0, 0%, 100%, 0.2);
}

.brand.footer {
  display: inline-block;
  float: none;
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
}

.brand.footer:hover {
  background-color: hsla(0, 0%, 100%, 0.2);
}

.brand.black {
  display: block;
  border-right-width: 1px;
  border-right-color: rgba(0, 0, 0, 0.15);
}

.brand.black.w--current {
  margin-left: 0px;
  border-top: 1px none rgba(0, 0, 0, 0.09);
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.15);
}

.brand.black.w--current:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

.upperheading-white {
  color: #fff;
  text-align: center;
}

.white {
  color: #fff;
  text-align: center;
}

.testimonials-text {
  margin-top: 20px;
  color: hsla(0, 0%, 100%, 0.7);
  font-size: 17px;
  line-height: 26px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
}

.testimonials-slider {
  border-right-style: none;
  background-color: transparent;
}

.slider-arrow {
  left: 0px;
  top: 0px;
  right: 0px;
  display: block;
  margin-right: 0px;
  margin-left: 0px;
  opacity: 0.5;
  -webkit-transition: opacity 500ms ease;
  transition: opacity 500ms ease;
}

.slider-arrow:hover {
  opacity: 1;
}

.slider-arrow.mobile {
  position: absolute;
  display: none;
  overflow: hidden;
}

.about-us-div {
  text-align: center;
}

.about-us-div.half-side {
  width: 50%;
}

.team-row {
  margin-top: 80px;
}

.team-name {
  margin-top: 30px;
  margin-bottom: 10px;
  font-family: Lato, sans-serif;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.social-link-block {
  margin-right: 2px;
  padding: 20px;
  border-right-style: none;
  background-color: #d1d1d1;
  background-image: url('src/assets/images/webflow/53f333fd1b92a02f2f930a05_facebook_footer.svg');
  background-position: 50% 50%;
  background-size: 20px;
  background-repeat: no-repeat;
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
}

.social-link-block:hover {
  border-right-width: 0px;
  border-right-color: transparent;
  background-color: #2a8241;
}

.social-link-block._4 {
  background-image: url('src/assets/images/webflow/53f33417144ad8302fd73d6a_twitter_footer.svg');
}

.social-link-block._3 {
  background-color: #d1d1d1;
  background-image: url('src/assets/images/webflow/53f3346a981411270eb11242_linkedin_footer.svg');
}

.social-link-block._3:hover {
  background-color: #2a8241;
  color: #000;
}

.social-link-block._2 {
  background-image: url('src/assets/images/webflow/53f3342f1b92a02f2f930a07_rss_footer.svg');
}

.team-position {
  margin-bottom: 10px;
}

.social-wrapper {
  margin-top: 30px;
  text-align: center;
}

.skills-section {
  padding-top: 120px;
  padding-bottom: 120px;
  background-image: linear-gradient(5deg, rgba(18, 46, 54, 0.5), rgba(18, 46, 54, 0.5)), url('src/assets/images/webflow/photo-1413991322434-bbd3f33c3232-min.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
}

.skills-section.alternative {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(16, 63, 38, 0.49)), to(rgba(16, 63, 38, 0.49))), url('src/assets/images/webflow/photo-1446233925202-75508684808f-min.jpg');
  background-image: linear-gradient(180deg, rgba(16, 63, 38, 0.49), rgba(16, 63, 38, 0.49)), url('src/assets/images/webflow/photo-1446233925202-75508684808f-min.jpg');
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, fixed;
}

.skills-wrapper {
  margin-bottom: 30px;
}

.skills-containner {
  width: 100%;
  height: 3px;
  background-color: #fff;
}

.skills-progress-line {
  width: 100%;
  height: 100%;
  background-color: #67bf7f;
}

.skills-progress-line.photography {
  width: 100%;
}

.skills-progress-line.illustrations {
  width: 75%;
}

.skills-progress-line.copywriting {
  width: 80%;
}

.skills-progress-line.photoshop {
  width: 95%;
}

.skills-progress-line.design {
  width: 85%;
}

.skills-row {
  margin-top: 80px;
}

.skills-title {
  color: #fff;
  font-weight: 400;
  text-align: left;
}

.skills-values {
  color: #fff;
  font-weight: 400;
  text-align: right;
}

.working-process-wrapper {
  margin-bottom: 47px;
  padding: 0px 15px 30px;
  border: 1px solid rgba(0, 0, 0, 0.09);
  background-color: #fff;
  box-shadow: 5px 6px 15px -8px #000;
  text-align: center;
}

.working-process-wrapper._2 {
  background-color: #fff;
}

.icon-service {
  width: 50px;
  height: 50px;
  margin-right: auto;
  margin-left: auto;
  background-color: #f5f5f5;
  background-position: 50% 50%;
  background-size: 25px;
  background-repeat: no-repeat;
}

.icon-service.planning {
  background-color: #f5f5f5;
  background-image: url('src/assets/images/webflow/icons8-blossom-64-2.png');
}

.icon-service.develop {
  background-color: #f5f5f5;
  background-image: url('src/assets/images/webflow/salt.png');
  background-size: 30px;
}

.icon-service.support {
  background-color: #f5f5f5;
  background-image: url('src/assets/images/webflow/aroma.png');
  background-size: 30px;
}

.icon-service.idea {
  background-color: #f5f5f5;
  background-image: url('src/assets/images/webflow/icons8-light-on-96_1icons8-light-on-96.png');
  background-size: 35px;
}

.icon-service.idea._2 {
  background-image: url('src/assets/images/webflow/salt.png');
}

.icon-service.idea._4 {
  background-image: url('src/assets/images/webflow/aroma.png');
}

.icon-service.idea._5 {
  background-image: url('src/assets/images/webflow/vitamins.png');
}

.icon-service.idea._6 {
  background-image: url('src/assets/images/webflow/cream.png');
}

.icon-service.idea._7 {
  background-image: url('src/assets/images/webflow/colon.png');
}

.process-row {
  margin-top: 40px;
}

.process-column {
  margin-top: 70px;
}

.process-column.second {
  margin-top: 0px;
}

.process-column.first {
  margin-top: 0px;
}

.portfolio-post {
  width: 100%;
  height: 300px;
  margin-bottom: 0px;
  background-image: url('src/assets/images/webflow/photo-1446575983799-470c50cfdd25-min.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.portfolio-post._2 {
  background-image: url('src/assets/images/webflow/photo-1430820937738-073612cc4c7e-min.jpg');
  background-position: 50% 0px;
  background-size: cover;
}

.portfolio-post._3 {
  background-image: url('src/assets/images/webflow/photo-1445796068163-f1115a21bbd9-min.jpg');
  background-position: 0px 100%;
  background-size: cover;
}

.portfolio-post._4 {
  background-image: url('src/assets/images/webflow/photo-1446233925202-75508684808f-min.jpg');
  background-position: 100% 100%;
  background-size: cover;
}

.portfolio-post._5 {
  background-image: url('src/assets/images/webflow/Dollarphotoclub_56332376-min.jpg');
  background-size: cover;
}

.portfolio-post._6 {
  background-image: url('src/assets/images/webflow/photo-1442422502730-a90f72460717-min.jpg');
  background-position: 0px 100%;
  background-size: cover;
}

.portfolio-post.single {
  height: 400px;
  margin-bottom: 10px;
}

.portfolio-post._7 {
  background-image: url('src/assets/images/webflow/photo-1429277096327-11ee3b761c93.jpg');
  background-size: cover;
}

.portfolio-post._8 {
  background-image: url('src/assets/images/webflow/L2NfDz5SOm7Gbf755qpw_DSCF0490-min.jpg');
  background-position: 0px 100%;
  background-size: cover;
}

.portfolio-link {
  width: 100%;
  height: 100%;
  padding: 0px;
}

.colorbox {
  width: 100%;
  height: 100%;
  padding-top: 130px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.85)), to(rgba(0, 0, 0, 0.85)));
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85));
  text-align: center;
}

.project-name {
  display: inline-block;
  width: 195px;
  height: 46px;
  padding-top: 13px;
  border: 1px solid hsla(0, 0%, 100%, 0.2);
  background-color: transparent;
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
  font-family: Oswald, sans-serif;
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 2px;
  text-decoration: none;
}

.project-name:hover {
  background-color: hsla(0, 0%, 100%, 0.2);
  color: #fff;
}

.portfolio-row {
  margin-top: 80px;
}

.portfolio-row.second {
  margin-top: 1px;
}

.portfolio-row.alternative {
  margin-top: 0px;
}

.portfolio-section {
  padding-top: 120px;
  padding-bottom: 10px;
}

.portfolio-section.alternative {
  padding-top: 10px;
}

.client-section {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 45px;
  padding-bottom: 45px;
  background-color: #e8e8e8;
}

.client-section.alternative {
  margin-top: 10px;
}

.client-column {
  opacity: 0.5;
  -webkit-transition: opacity 500ms ease;
  transition: opacity 500ms ease;
  text-align: center;
}

.client-column:hover {
  opacity: 1;
}

.contact-us-section {
  margin-bottom: 0px;
  padding-top: 120px;
  padding-bottom: 120px;
  background-image: linear-gradient(135deg, hsla(0, 0%, 100%, 0.89), hsla(0, 0%, 100%, 0.9)), url('src/assets/images/canon/statue.JPG');
  background-size: contain;
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
}

.form-field {
  min-width: 0px;
  border: 1px solid transparent;
  font-family: Lato, sans-serif;
  color: #737373;
}

.form-field.message {
  height: 120px;
}

.form-text {
  font-family: Lato, sans-serif;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.contact-column {
  text-align: right;
}

.conatct-info-text {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}

.conatct-info-text.address {
  margin-top: 20px;
  margin-bottom: 20px;
}

.contact-row {
  margin-top: 80px;
}

.form-wrapper {
  margin-top: 0px;
}

.footer-section {
  margin-top: 0px;
  padding-top: 80px;
  padding-bottom: 60px;
  background-color: transparent;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(18, 46, 54, 0.94)), to(rgba(18, 46, 54, 0.94))), url('src/assets/images/canon/haus.JPG');
  background-image: linear-gradient(180deg, rgba(18, 46, 54, 0.94), rgba(18, 46, 54, 0.94)), url('src/assets/images/canon/haus.JPG');
  background-position: 0px 0px, 50% 0px;
  background-size: auto, cover;
}

.footer-containner {
  text-align: center;
}

.white-paragraph {
  display: inline-block;
  margin-top: 50px;
  margin-bottom: 40px;
  float: none;
  color: hsla(0, 0%, 100%, 0.56);
}

.white-paragraph.licenses {
  color: hsla(0, 0%, 100%, 0.75);
  font-weight: 400;
  text-decoration: underline;
  text-transform: none;
}

.arrow {
  margin-top: 600px;
}

.arrow.right {
  top: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  margin: 0px 30px 0px 0px;
  padding-top: 5px;
  padding-bottom: 8px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #fff;
  background-color: transparent;
  opacity: 1;
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
  font-size: 20px;
}

.arrow.right:hover {
  background-color: hsla(0, 0%, 100%, 0.09);
  opacity: 1;
}

.arrow.left {
  top: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50px;
  height: 50px;
  margin: 0px 0px 0px 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #fff;
  background-color: transparent;
  opacity: 1;
  -webkit-transition: background-color 500ms ease;
  transition: background-color 500ms ease;
  font-size: 20px;
}

.arrow.left:hover {
  background-color: hsla(0, 0%, 100%, 0.09);
  opacity: 1;
  -webkit-transition-property: none;
  transition-property: none;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}

.slidenav {
  display: none;
}

.containner-slide {
  position: relative;
  top: 40%;
  text-align: center;
}

.portfolio-column {
  min-height: 0px;
  padding-right: 1px;
  padding-left: 0px;
}

.white-section {
  height: 10px;
}

.hero-section {
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.55)), to(transparent)), url('src/assets/images/webflow/photo-1446233925202-75508684808f-min.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.55), transparent), url('src/assets/images/webflow/photo-1446233925202-75508684808f-min.jpg');
  background-position: center;
  background-size: auto, cover;
}

@media only screen and (max-width: 3845px) {
  .hero-section {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.55)), to(transparent)), url('src/assets/images/webflow/photo-1446233925202-75508684808f-min-3840.jpg');
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.55), transparent), url('src/assets/images/webflow/photo-1446233925202-75508684808f-min-3840.jpg');
  }
}

@media only screen and (max-width: 1925px) {
  .hero-section {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.55)), to(transparent)), url('src/assets/images/webflow/photo-1446233925202-75508684808f-min-1920.jpg');
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.55), transparent), url('src/assets/images/webflow/photo-1446233925202-75508684808f-min-1920.jpg');
  }
}

@media only screen and (max-width: 1080px) {
  .hero-section {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.55)), to(transparent)), url('src/assets/images/webflow/photo-1446233925202-75508684808f-min-1080.jpg');
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.55), transparent), url('src/assets/images/webflow/photo-1446233925202-75508684808f-min-1080.jpg');
  }
}

.hero-section.licenses {
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.55)), to(transparent)), url('src/assets/images/webflow/photo-1430820937738-073612cc4c7e-min.jpg');
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.55), transparent), url('src/assets/images/webflow/photo-1430820937738-073612cc4c7e-min.jpg');
  background-position: 0px 0px, 50% 0px;
  background-size: auto, cover;
}

.project-section {
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #f5f5f5;
}

.info-title {
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: Oswald, sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-align: left;
}

.info-text {
  margin-bottom: 50px;
  text-align: left;
}

.success {
  background-color: #2a8241;
  color: #fff;
}

.success-text {
  color: #fff;
  font-weight: 500;
}

.important-text {
  padding-bottom: 0px;
  color: #000;
  letter-spacing: 1px;
}

.error-message {
  background-color: #000;
}

.error-text {
  color: #fff;
}

.navbar-scroll {
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1111;
  display: block;
  width: 100%;
  margin-top: 0px;
  margin-right: 0px;
  margin-left: 0px;
  background-color: #fff;
  box-shadow: none;
  -webkit-transform: translate(0px, -102%);
  -ms-transform: translate(0px, -102%);
  transform: translate(0px, -102%);
}

.banner-section {
  height: 100vh;
}

.submit-button {
  margin-top: 30px;
  padding: 15px 20px;
  border: 1px solid #000;
  background-color: transparent;
  -webkit-transition: background-color 500ms ease, border-color 500ms ease, color 500ms ease;
  transition: background-color 500ms ease, border-color 500ms ease, color 500ms ease;
  font-family: Lato, sans-serif;
  color: #000;
  font-size: 12px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.submit-button:hover {
  border-color: #2a8241;
  background-color: #2a8241;
  color: #fff;
}

.licenses-content {
  padding-top: 60px;
  padding-bottom: 60px;
}

.div-infolicenses {
  padding: 30px;
  background-color: #f0f0f0;
}

.image-name {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 14px;
  padding-bottom: 14px;
  font-family: Oswald, sans-serif;
  text-align: left;
}

.list-image {
  display: block;
  margin-bottom: 1px;
  padding-left: 20px;
  background-color: #fff;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
}

.list-image:hover {
  background-color: rgba(42, 130, 65, 0.18);
}

.unordered-list {
  padding-left: 0px;
}

.licenses {
  padding: 0px;
}

.dropdown {
  display: block;
  padding-top: 25px;
  padding-bottom: 25px;
  border-left: 1px solid hsla(0, 0%, 100%, 0.2);
}

.dropdown.w--open:hover {
  background-color: hsla(0, 0%, 100%, 0.09);
}

.dropdown-title {
  font-family: Oswald, sans-serif;
  color: #fff;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.iocn {
  color: hsla(0, 0%, 100%, 0.5);
}

.dropdown-link {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.19);
  -webkit-transition: background-color 400ms ease, color 400ms ease;
  transition: background-color 400ms ease, color 400ms ease;
  color: #fff;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 1px;
}

.dropdown-link:hover {
  background-color: #2a8241;
  color: #fff;
}

.dropdown-link.w--current {
  background-color: #333;
  color: #fff;
}

.dropdown-link.w--current:hover {
  background-color: #2a8241;
}

.dropdown-list.w--open {
  width: 170px;
  margin-top: 1px;
  background-color: #1d1d1d;
}

.utility-page-wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100vw;
  height: 100vh;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.utility-page-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 260px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

.utility-page-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.licence-items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 60%;
  margin-right: auto;
  margin-bottom: -24px;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.licence-item-firs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 24px;
  padding: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #dde1e7;
  border-radius: 0px;
  background-color: #fff;
}

.image-div {
  width: 100px;
  height: 100px;
  background-image: url('src/assets/images/webflow/shutterstock_178724276.jpg');
  background-position: 50% 50%;
  background-size: cover;
}

.image-div.logo {
  width: 60px;
  height: 60px;
  margin-right: 20px;
  background-image: url('src/assets/images/webflow/Untitled-22.png');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.licence-link {
  color: #343e52;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  text-decoration: none;
}

.licence-link:hover {
  color: #fa7000;
  text-decoration: underline;
}

.licence-link.changelog:hover {
  color: #2a8241;
}

.body {
  border-style: none;
}

.container {
  display: block;
  text-align: center;
}

.body-2 {
  border-style: none;
}

.text-block {
  color: #fff;
}

.div-block {
  margin-left: 0px;
}

.team-circles {
  position: relative;
  padding: 80px 30px;
  border-bottom: 1px solid #e4ebf3;
}

.container-2 {
  width: 100%;
  max-width: 940px;
  margin-right: auto;
  margin-left: auto;
}

.centered-heading {
  margin-bottom: 16px;
  text-align: center;
}

.centered-heading.margin-bottom-32px {
  margin-bottom: 32px;
}

.centered-subheading {
  max-width: 530px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.team-grid {
  display: -ms-grid;
  display: grid;
  margin-top: 50px;
  grid-auto-columns: 1fr;
  grid-column-gap: 64px;
  grid-row-gap: 56px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.team-card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
}

.team-member-image {
  width: 270px;
  height: 270px;
  margin-bottom: 24px;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}

.team-member-name {
  margin-bottom: 6px;
  font-size: 20px;
  line-height: 32px;
  font-weight: 500;
}

.team-member-position {
  margin-bottom: 24px;
}

.hero-heading-center {
  position: relative;
  padding: 80px 30px;
  border-bottom: 1px solid #e4ebf3;
  background-color: #f5f7fa;
}

.hero-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.hero-split {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 46%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.margin-bottom-24px {
  margin-bottom: 24px;
}

.button-primary {
  padding: 12px 25px;
  background-color: #1a1b1f;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.button-primary:hover {
  background-color: #32343a;
  color: #fff;
}

.button-primary:active {
  background-color: #43464d;
}

.shadow-two {
  box-shadow: 0 4px 24px 0 rgba(150, 163, 181, 0.08);
}

.team-slider {
  position: relative;
  padding: 80px 30px;
  border-bottom: 1px solid #e4ebf3;
  background-color: #f5f7fa;
}

.team-slider-wrapper {
  height: auto;
  margin-top: 50px;
  background-color: transparent;
}

.team-slide-wrapper {
  width: 30%;
  margin-right: 5%;
}

.team-block {
  padding-bottom: 24px;
  background-color: #fff;
}

.team-member-image-two {
  margin-bottom: 18px;
}

.team-block-info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 24px;
  padding-left: 24px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.team-member-name-two {
  margin-bottom: 12px;
  font-weight: 600;
}

.team-member-text {
  margin-bottom: 20px;
}

.text-link-arrow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #1a1b1f;
  font-size: 14px;
  line-height: 20px;
  text-decoration: none;
}

.arrow-embed {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 2px;
}

.team-slider-arrow {
  display: none;
}

.team-slider-nav {
  position: static;
  bottom: -60px;
  margin-top: 24px;
  font-size: 10px;
}

.text-span {
  text-decoration: line-through;
}

.link {
  color: #fff;
}

@media screen and (min-width: 1440px) {
  .social-link-block._3 {
    background-size: 20px;
  }

  .social-link-block._2 {
    background-image: url('src/assets/images/webflow/53f3346a981411270eb11242_linkedin_footer.svg');
  }

  .conatct-info-text {
    color: hsla(0, 0%, 100%, 0.56);
    text-align: center;
  }

  .conatct-info-text.address {
    background-color: hsla(0, 0%, 100%, 0);
    color: hsla(0, 0%, 100%, 0.56);
  }

  .team-member-image {
    width: 270px;
    height: 270px;
    max-height: none;
  }

  .link {
    color: #fff;
  }
}

@media screen and (max-width: 991px) {
  body {
    height: 100%;
    margin-bottom: 10px;
  }

  .hero-heading {
    font-size: 52px;
    line-height: 98px;
    letter-spacing: 2px;
  }

  .hero-subheader {
    margin-top: 0px;
  }

  .nav-link {
    border-style: solid solid none;
    border-width: 1px;
    border-color: hsla(0, 0%, 100%, 0);
  }

  .nav-link.w--current {
    background-color: hsla(0, 0%, 100%, 0.09);
  }

  .nav-link.black {
    display: block;
    border-bottom-style: solid;
    color: #fff;
  }

  .nav-link.black:hover {
    background-color: hsla(0, 0%, 100%, 0.09);
  }

  .nav-link.black.w--current {
    display: block;
  }

  .section.about {
    background-position: 0% 0%, 60% 100%;
  }

  .section.about.alternative {
    background-position: 0px 0px, 82% 50%;
    background-size: auto, 200px;
  }

  .slider-arrow.mobile {
    display: none;
  }

  .process-row {
    margin-top: 70px;
  }

  .process-column {
    margin-top: 10px;
  }

  .portfolio-post {
    height: 350px;
    margin-bottom: 1px;
  }

  .colorbox {
    padding-top: 160px;
  }

  .portfolio-row.second {
    margin-top: 0px;
  }

  .form-field {
    font-weight: 300;
  }

  .form-field.message {
    height: 140px;
  }

  .footer-section {
    margin-bottom: 0px;
    padding-bottom: 60px;
  }

  .icon {
    margin-top: 5px;
    color: #fff;
  }

  .icon.black {
    color: #000;
  }

  .menu-button {
    width: 60px;
    height: 60px;
    border-left: 1px solid hsla(0, 0%, 100%, 0.2);
  }

  .menu-button.w--open {
    background-color: transparent;
  }

  .menu-button.black {
    width: 61px;
    height: 71px;
    border-left-color: rgba(0, 0, 0, 0.15);
    background-color: transparent;
  }

  .nav-menu {
    margin-right: 10px;
    margin-left: 10px;
    background-color: rgba(0, 0, 0, 0.85);
  }

  .arrow.right {
    top: 87%;
  }

  .arrow.left {
    top: 87%;
  }

  .portfolio-column {
    padding-right: 0px;
  }

  .navbar-scroll {
    display: none;
    box-shadow: none;
  }

  .banner-section {
    height: 100%;
  }

  .dropdown {
    padding-left: 25px;
  }

  .dropdown:hover {
    background-color: hsla(0, 0%, 100%, 0.09);
  }

  .licence-items {
    width: 80%;
  }

  .container-2 {
    max-width: 728px;
  }

  .team-grid {
    grid-column-gap: 40px;
  }

  .team-member-image {
    width: 190px;
    height: 190px;
  }

  .hero-wrapper {
    margin-bottom: -40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .hero-split {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .team-slide-wrapper {
    width: 47%;
    margin-right: 6%;
  }
}

@media screen and (max-width: 767px) {
  .button {
    margin-bottom: 0px;
  }

  .button.hollow.single {
    margin-bottom: 70px;
  }

  .button.hollow.new {
    margin-bottom: 100px;
  }

  .main-slider {
    height: 100%;
  }

  .hero-heading {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 38px;
    line-height: 68px;
  }

  .hero-subheader {
    margin-top: 0px;
    font-size: 15px;
  }

  .features-section {
    margin-top: 0px;
  }

  .nav-link {
    width: 100%;
    max-width: 1500px;
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .features-div {
    margin-right: 0px;
  }

  .features-div.second {
    margin-right: 0px;
  }

  .section.about {
    padding-bottom: 550px;
    background-position: 0% 0%, 88% 100%;
    background-size: auto, auto 650px;
  }

  .section.about.alternative {
    background-position: 0px 0px, 50% 92%;
    background-size: auto, 180px;
  }

  .section.process {
    background-position: 0px 0px, 50% 100%;
    background-size: auto, auto;
  }

  .brand {
    padding-left: 30px;
  }

  .testimonials-text {
    margin-right: 10px;
    margin-left: 10px;
  }

  .slider-arrow.mobile {
    display: none;
  }

  .about-us-div.half-side {
    width: 100%;
  }

  .social-wrapper {
    margin-bottom: 50px;
  }

  .process-column.second {
    margin-top: 0px;
  }

  .process-column.first {
    margin-top: 50px;
  }

  .portfolio-post {
    margin-bottom: 1px;
  }

  .client-column {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .contact-us-section {
    background-position: 0% 0%, 68% 100%;
    background-size: auto, cover;
  }

  .footer-section {
    padding-bottom: 10px;
  }

  .nav-menu {
    position: absolute;
    z-index: 1;
  }

  .arrow.right {
    top: 87%;
  }

  .arrow.left {
    top: 87%;
    margin-bottom: 0px;
  }

  .project-section {
    padding-bottom: 0px;
  }

  .navbar-scroll {
  }

  .banner-section {
    height: 660px;
  }

  .submit-button {
    margin-bottom: 70px;
  }

  .dropdown {
    width: 100%;
    max-width: 1500px;
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .licence-items {
    width: 100%;
  }

  .licence-item-firs {
    width: 100%;
  }

  .div-block {
    margin-right: 10px;
    margin-left: 10px;
  }

  .team-circles {
    padding: 60px 15px;
  }

  .team-grid {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .hero-heading-center {
    padding: 60px 15px;
  }

  .team-slider {
    padding: 60px 15px;
  }
}

@media screen and (max-width: 479px) {
  a {
    text-align: center;
  }

  .button.hollow.new {
    margin-bottom: 100px;
  }

  .hero-heading {
    font-size: 35px;
    line-height: 45px;
    letter-spacing: 3px;
  }

  .hero-subheader {
    margin-top: 10px;
    font-size: 14px;
    line-height: 25px;
  }

  .features-section {
    margin-top: 0px;
  }

  .nav-link {
    height: auto;
  }

  .features-div {
    margin-right: 0px;
  }

  .features-div.second {
    margin-right: 0px;
  }

  .section.about {
    background-position: 0% 0%, 77% 100%;
  }

  .testimonials-section {
    padding-bottom: 80px;
  }

  .testimonials-text {
    font-size: 17px;
  }

  .testimonials-slider {
    height: auto;
  }

  .slider-arrow {
    opacity: 0.35;
  }

  .slider-arrow.mobile {
    display: none;
  }

  .social-wrapper {
    margin-bottom: 40px;
  }

  .process-column {
    margin-top: 0px;
  }

  .client-column {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .contact-us-section {
    background-position: 0% 0%, 78% 50%;
    background-size: auto, cover;
  }

  .footer-section {
    padding-bottom: 20px;
  }

  .white-paragraph {
    margin-right: 5px;
    margin-left: 5px;
  }

  .slider-nav {
    padding-top: 45px;
  }

  .arrow.right {
    top: 87%;
  }

  .arrow.left {
    top: 87%;
  }

  .image-name {
    line-height: 20px;
    text-align: center;
  }

  .list-image {
    padding-left: 0px;
  }

  .dropdown {
    height: auto;
    padding-top: 11px;
    padding-bottom: 11px;
  }

  .licence-item-firs {
    width: auto;
    padding: 15px 15px 14px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .image-div {
    width: 100px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .image-div.logo {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .licence-link {
    margin-right: 10px;
  }

  .licence-link.changelog {
    margin-right: 0px;
    text-align: center;
  }

  .div-block {
    margin-right: 10px;
    margin-left: 10px;
  }

  .container-2 {
    max-width: none;
  }

  .centered-heading {
    margin-bottom: 24px;
  }

  .team-grid {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .team-slide-wrapper {
    width: 100%;
    margin-right: 0%;
  }
}

#w-node-f7711791-17ec-1bcb-4a97-dc5dbe56f621-427834bf {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f7711791-17ec-1bcb-4a97-dc5dbe56f62c-427834bf {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f7711791-17ec-1bcb-4a97-dc5dbe56f636-427834bf {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f7711791-17ec-1bcb-4a97-dc5dbe56f640-427834bf {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f7711791-17ec-1bcb-4a97-dc5dbe56f64a-427834bf {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

#w-node-f7711791-17ec-1bcb-4a97-dc5dbe56f654-427834bf {
  -ms-grid-column: span 1;
  grid-column-start: span 1;
  -ms-grid-column-span: 1;
  grid-column-end: span 1;
  -ms-grid-row: span 1;
  grid-row-start: span 1;
  -ms-grid-row-span: 1;
  grid-row-end: span 1;
}

@media screen and (min-width: 1440px) {
  #w-node-f7711791-17ec-1bcb-4a97-dc5dbe56f62c-427834bf {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }

  #w-node-f7711791-17ec-1bcb-4a97-dc5dbe56f636-427834bf {
    -ms-grid-column: span 1;
    grid-column-start: span 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
  }
}

